$(function () {

    const HOME_PRODUCTS         = $('#home-products');
    const RELATED_PRODUCTS      = $('#other-product-related');
    const OFFERED_PRODUCTS      = $('#other-product-offer');
    const PASTRY_SHOP_CAROUSEL  = $('#pastry-shop-carousel');


    if (HOME_PRODUCTS.length) {
        HOME_PRODUCTS.owlCarousel({
            loop: false,
            margin: 17,
            nav: false,
            dots: false,
            singleItem: true,
            autoplay: false,
            smartSpeed: 500,
            mouseDrag: true,
            responsive: {
                0: {
                    items: 1,
                    stagePadding: 50,
                },
                600: {
                    items: 1,
                    stagePadding: 0,
                },
                1000: {
                    items: 4
                }
            }
        });
    }

    if (RELATED_PRODUCTS.length) {
        RELATED_PRODUCTS.owlCarousel({
            loop: false,
            margin: 17,
            nav: false,
            dots: false,
            autoplay: false,
            smartSpeed: 500,
            mouseDrag: false,
            responsive: {
                0: {
                    items: 1,
                    stagePadding: 25,
                    mouseDrag: true,
                },
                600: {
                    items: 2,
                    margin: 10,
                    mouseDrag: true,
                },
                700: {
                    items: 2,
                    stagePadding: 0,
                },
                1000: {
                    items: 4
                }
            }
        });
    }

    if (OFFERED_PRODUCTS.length) {
        OFFERED_PRODUCTS.owlCarousel({
            loop: false,
            margin: 17,
            nav: false,
            dots: false,
            autoplay: false,
            smartSpeed: 500,
            mouseDrag: false,
            responsive: {
                0: {
                    items: 1,
                    stagePadding: 25,
                    mouseDrag: true,
                },
                600: {
                    items: 2,
                    margin: 10,
                    mouseDrag: true,
                },
                700: {
                    items: 2,
                    stagePadding: 0,
                },
                1000: {
                    items: 4
                }
            }
        });
    }

    if (PASTRY_SHOP_CAROUSEL.length) {
        PASTRY_SHOP_CAROUSEL.owlCarousel({
            loop: false,
            margin: 17,
            nav: false,
            dots: false,
            autoplay: false,
            smartSpeed: 500,
            mouseDrag: false,
            responsive: {
                0: {
                    items: 1,
                    stagePadding: 25,
                },
                600: {
                    items: 3,
                    stagePadding: 25,
                },
                1000: {
                    items: 4
                }
            }
        });
    }

    // related products nav

    $('#more-product-related-nav .left').on('click',function(){
        var leftPos = $('#other-product-related .owl-stage-outer').scrollLeft();
        $('#other-product-related .owl-stage').animate({scrollLeft: leftPos - 500});
        $('#other-product-related .owl-stage-outer').animate({scrollLeft: leftPos - 500});
    })

    $('#more-product-related-nav .right').on('click',function(){
        var leftPos = $('#other-product-related .owl-stage-outer').scrollLeft();
        $('#other-product-related .owl-stage').animate({scrollLeft: leftPos + 500});
        $('#other-product-related .owl-stage-outer').animate({scrollLeft: leftPos + 500});
    })

    // Offered nav

    $('#more-product-offer-nav .left').on('click',function(){
        var leftPos = $('#other-product-offer .owl-stage-outer').scrollLeft();
        $('#other-product-offer .owl-stage').animate({scrollLeft: leftPos - 500});
        $('#other-product-offer .owl-stage-outer').animate({scrollLeft: leftPos - 500});
    })

    $('#more-product-offer-nav .right').on('click',function(){
        var leftPos = $('#other-product-offer .owl-stage-outer').scrollLeft();
        $('#other-product-offer .owl-stage').animate({scrollLeft: leftPos + 500});
        $('#other-product-offer .owl-stage-outer').animate({scrollLeft: leftPos + 500});
    })

    // Pastry nav

    $('#pastry-shop-carousel-nav .left').on('click',function(){
        var leftPos = $('#pastry-shop-carousel .owl-stage-outer').scrollLeft();
        $('#pastry-shop-carousel .owl-stage').animate({scrollLeft: leftPos - 500});
        $('#pastry-shop-carousel .owl-stage-outer').animate({scrollLeft: leftPos - 500});
    })

    $('#pastry-shop-carousel-nav .right').on('click',function(){
        var leftPos = $('#pastry-shop-carousel .owl-stage-outer').scrollLeft();
        $('#pastry-shop-carousel .owl-stage').animate({scrollLeft: leftPos + 500});
        $('#pastry-shop-carousel .owl-stage-outer').animate({scrollLeft: leftPos + 500});
    })

});
