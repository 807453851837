import 'lightgallery/src/js/lightgallery';


$(function () {
    $('[data-toggle="tooltip"]').tooltip()

    $(document).on('click', '.nav-items .dropdown-menu', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', '.mobile__nav-icon', function(e){
        $('#navbar__input-search-mobile').focus();
    });

    $(document).on('click', '.page-link-scroll-to-top', function (e) {
        $("html, body").animate({ scrollTop: 0 }, 'smooth');
        return false;
    });

    const lightGalleryContainer    = $('#lightGallery');
    const mapCarousel              = $('#mapCarousel');
    const cartCarousel             = $('#cartCarousel');
    const navExpand                = [].slice.call(document.querySelectorAll('.nav-expand'));
    const accordionCard            = $('.accordion__card');


    if (lightGalleryContainer.length) {
        lightGalleryContainer.lightGallery({
            showThumbByDefault: false,
        });
    }

    if (mapCarousel.length) {
        mapCarousel.owlCarousel({
            items: 1,
            pullDrag: true,
            freeDrag: false,
            mouseDrag: false,
            nav: true,
            navText: ['<img width="24" height="24" aria-label="Back" alt="Back" src="/assets/website/images/chevron-back.svg" class="navbar__input-icon">', '<img width="24" height="24" aria-label="Profil" alt="Profil" src="/assets/website/images/chevron-next.svg" class="navbar__input-icon">'],
            video: true,
            dots: false,
            autoplay: false,
            responsiveRefreshRate: 200,
        });
    }

    if (cartCarousel.length) {
        cartCarousel.owlCarousel({
            items: 5,
            pullDrag: true,
            freeDrag: false,
            mouseDrag: false,
            nav: true,
            navText: ['<img width="24" height="24" aria-label="Back" alt="Back" src="/assets/website/images/chevron-back.svg" class="navbar__input-icon">', '<img width="24" height="24" aria-label="Profil" alt="Profil" src="/assets/website/images/chevron-next.svg" class="navbar__input-icon">'],
            video: true,
            dots: false,
            autoplay: false,
            responsiveRefreshRate: 200,
            margin: 32,
        });
    }


    Livewire.on('showAlert', function(){
        setTimeout(function () {
            $('#alert').hide();
        }, 3000);
    });

    navExpand.forEach(item => {
        item.querySelector('.nav-link').addEventListener('click', () => item.classList.add('active'))
        item.querySelector('.nav-back-link').addEventListener('click', () => item.classList.remove('active'))
    })

    tippy('[data-tippy-content]');

    $(document).on('click', '#dropdownBodyLocale', function (e) {
        e.stopPropagation();
    });

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    $('.alert__close').on('click', function () {
        setCookie('alert_accepted', true, 1);
    })

    if (accordionCard.length) {
        accordionCard.on('show.bs.collapse', function () {
            $(this).addClass('accordion__shadow');
        })

        accordionCard.on('hide.bs.collapse', function () {
            $(this).removeClass('accordion__shadow');
        })
    }

    $('#socialLoginGoogle').prop("disabled", true);
    $('#socialLoginFacebook').prop("disabled", true);
    $('#acceptASZFandASZForGoogle').click(function () {
        $('#socialLoginGoogle').prop("disabled", !$("#acceptASZFandASZForGoogle").prop("checked"));
        $('#checkASZFandASZForGoogle').val($("#acceptASZFandASZForGoogle").prop("checked"));

    });
    $('#acceptASZFandASZForFacebook').click(function () {
        $('#socialLoginFacebook').prop("disabled", !$("#acceptASZFandASZForFacebook").prop("checked"));
        $('#checkASZFandASZForFacebook').val($("#acceptASZFandASZForFacebook").prop("checked"));
    });

    $(".togglePassword").on('click', function () {

        let $password      = $(this).parent().children(".password");
        let $passwordType  = $password.attr('type');
        let $img           = $(this).find('img');

        if ($passwordType === 'password') {
            $password.attr('type', 'text');
            $img.attr('src', '../../assets/website/images/pwd-hide.svg');
        } else {
            $password.attr('type', 'password');
            $img.attr('src', '../../assets/website/images/pwd-icon.svg');
        }
    });

    if($('#address-edit input[name=type]:checked').val() == 'delivery') {
        $('#company-data').hide();
        $('#default-billing-address').hide();
        $('#default-address').fadeIn();
        $('#tax_no').fadeOut();
    }else {
        $('#default-address').hide();
        $('#default-billing-address').fadeIn();
        $('#company-data').fadeIn();
        $('#tax_no').fadeIn();
    }

    $('#address-edit input[name=type]').change(function() {
        if (this.value == 'delivery') {
            $('#tax_no').fadeOut();
        }
        else if (this.value == 'billing') {
            $('#tax_no').fadeIn();
        }
    });

    $('#address-edit input').on('change', function() {
        let type = $('#address-edit input[name=type]:checked').val();

        if(type == 'delivery') {
            $('#company-data').hide();
            //$('#default-address').fadeIn();
        }else {
            $('#company-data').fadeIn();
            //$('#default-address').hide();
        }
    });


    $('.default__address-delivery').on('click', function () {
        if ($(this).prop('checked')) {
            let id = $(this).data('id');
            let url = $(this).data('url');
            $.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        default_address_id: id
                    },
                })
                .done(function (data) {
                    if (data == 'OK') {
                        //alert('sikeres adatmodositas.. // popup');
                    } else {
                        // alert('sikertelen adatmodositas.. // popup');
                    }
                });
        }
    })

    $('.default__address-billing').on('click', function () {
        if ($(this).prop('checked')) {
            let id = $(this).data('id');
            let url = $(this).data('url');
            $.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        default_billing_address_id: id
                    },
                })
                .done(function (data) {
                    if (data == 'OK') {
                        //alert('sikeres adatmodositas.. // popup');
                    } else {
                        // alert('sikertelen adatmodositas.. // popup');
                    }
                });
        }
    })
});
