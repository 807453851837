$(function () {
    var sync1           = $("#sync1");
    var sync2           = $("#sync2");

    sync1.owlCarousel({
        items: 1,
        pullDrag: false,
        freeDrag: false,
        mouseDrag: false,
        nav: false,
        video: true,
        autoplay: false,
        videoHeight: 500,
        videoWidth: 500,
        dots: false,
        responsiveRefreshRate: 100,
    }).on('changed.owl.carousel', syncPosition);

    sync2
        .owlCarousel({
            loop: false,
            margin: 8,
            nav: true,
            rewindNav:false,
            stagePadding: 0,
            items: 5,
            mouseDrag: false,
            dots: false,
            video: false,
            navContainer: '.naver',
            navText: ['<img width="15" height="15" aria-label="Back" alt="Back" src="/assets/website/images/chev-back.svg" class="navbar__input-icon">', '<img width="15" height="15" aria-label="Profil" alt="Profil" src="/assets/website/images/chev-next.svg" class="navbar__input-icon">'],
            responsive: {
                0: {
                    items: 3,
                    nav: false,
                    stagePadding: 50,
                    pullDrag: true,
                    mouseDrag: true,
                },
                600: {
                    items: 3,
                    nav: false,
                    stagePadding: 50,
                    pullDrag: true,
                    mouseDrag: true,
                },
                700: {
                    items: 3,
                    pullDrag: false,
                    nav: true
                },
                800: {
                    items: 3,
                    pullDrag: false,
                    nav: true
                },
                1000: {
                    items: 4,
                    pullDrag: false,
                    nav: true
                },
                1200: {
                    items: 4,
                    pullDrag: false,
                    nav: true
                }
            }
        })


    function syncPosition(el) {
        var current = el.item.index;
        var current = Math.round(el.item.index - (el.item.count / 2) - .5);
        sync2
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
    }

    sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });

    $('.image-zoomer').zoom();
});
