$(function () {
    $("#deleteAddressModal").on("show.bs.modal", function (event) {
        let button = $(event.relatedTarget);
        let action = button.data("action");
        let modal = $(this);
        modal.find("form").attr("action", action);
    });

    $("#deleteUserModal").on("show.bs.modal", function (event) {
        let button = $(event.relatedTarget);
        let action = button.data("action");
        let modal = $(this);
        modal.find("form").attr("action", action);
    });
});
