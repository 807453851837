
$(function () {

    const sidebar            = $('#sidebar');
    const categorySidebar    = $('#categorySidebar');
    const searchOverlay      = $('#search-overlay');
    const generalOverlay     = $('#filter-overlay');
    const searchDropdown     = $('#search-dropdown');
    logoSizeManager();
    
    window.onscroll = () => {
        logoSizeManager();
    };

    window.onresize = () => {
        logoSizeManager();
    };

    function logoSizeManager() {

        let mainNav        = $('#main__nav');
        let navHeight      = $('#main__nav>.con');
        let logo           = $("#main__logo");
        let navBottom      = $('.navbar__bottom');
        let categoryButton = $('#categorySidebarCollapseHide');

        var iev=0;
        var ieold = (/MSIE (\d+\.\d+);/.test(navigator.userAgent));
        var trident = !!navigator.userAgent.match(/Trident\/7.0/);
        var rv=navigator.userAgent.indexOf("rv:11.0");
    
        if (ieold) iev=new Number(RegExp.$1);
        if (navigator.appVersion.indexOf("MSIE 10") != -1) iev=10;
        if (trident&&rv!=-1) iev=11;
    
        // Firefox or IE 11
        if(typeof InstallTrigger !== 'undefined' || iev == 11) {
            var lastScrollTop = 0;
            $(window).on('scroll', function() {
                st = $(this).scrollTop();
                if(st < lastScrollTop) {
                    logo.removeClass("decreased");
                    navHeight.removeClass("decreased");
                    mainNav.removeClass("decreased");
                    navBottom.css('top','150px');
                    categoryButton.removeClass('decreased');
                }
                else if(st > lastScrollTop) {
                    logo.addClass("decreased");
                    categoryButton.addClass('decreased');
                    mainNav.addClass("decreased");
                    navBottom.css('top','auto');
                    navHeight.addClass("decreased");
                }
                lastScrollTop = st;
            });
        }
        // Other browsers
        else {
            $('body').on('mousewheel', function(e){
                if(e.originalEvent.wheelDelta > 0) {
                    logo.removeClass("decreased");
                    navHeight.removeClass("decreased");
                    mainNav.removeClass("decreased");
                    navBottom.css('top','150px');
                    categoryButton.removeClass('decreased');
                }
                else if(e.originalEvent.wheelDelta < 0) {
                    logo.addClass("decreased");
                    categoryButton.addClass('decreased');
                    mainNav.addClass("decreased");
                    navBottom.css('top','auto');
                    navHeight.addClass("decreased");
                }
            });
        }
    }
    

    $(document).on('click', '#dismiss, .dismiss, .overlay, #showProducts, #clearProducts', function () {
        sidebar.removeClass('active');
        categorySidebar.removeClass('active');
        $(".overlay.active").removeClass("active");
        $('body').css('overflow','auto');
        searchDropdown.css('display', 'none');
    });
    

    $('#sidebarCollapse').on('click', function () {
        sidebar.addClass('active');
        generalOverlay.addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });

    $('#categorySidebarCollapse').on('click', function () {
        categorySidebar.addClass('active');
        generalOverlay.addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });

    $('#categorySidebarCollapseHide').on('click', function () {
        categorySidebar.addClass('active');
        generalOverlay.addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });

    $('#navbar__input-search').on('focus', function () {
        searchOverlay.addClass('active');
        $('body').css('overflow','hidden');
        searchDropdown.css('display', 'block');
    });

    (function ($) {
        $(".mCustomScrollbar").mCustomScrollbar({
            theme: "dark"
        });
    })(jQuery);

    (function ($) {
        $(".mCustomScrollbar-category").mCustomScrollbar({
            theme: "dark"
        });
    })(jQuery);
});
