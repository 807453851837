import './bootstrap';
import './custom-nav';
import 'owl.carousel';
import './scrollbar';
import './cloudimage';
import './custom-scroll';
import './product-carousel';
import './products-swiper';
import 'tippy.js'
import 'tippy.js/dist/tippy.css';
import './custom.js';
import './giveaway.js';
import './modals.js';
import 'jquery-zoom'

//statikus kepek
require('../images/stuhmer_favicon.png');
require('../images/simple-pay.svg');
require('../images/simplepay-payments.png');

require('../images/owl.video.play.png');
require('../images/cukraszda.png');

require('../images/szechenyi.jpg');

require('../images/stuhmer-logo.svg');
require('../images/stuhmer-logo-white.svg');
require('../images/loading.gif');
require('../images/b2blogo.svg');

require('../images/csoki-half.png');
require('../images/csoki.png');
require('../images/violin.svg');

require('../images/newsletter.png');

require('../images/404.png');
require('../images/choco_bg.webp');
require('../images/chocho_mobile_bg.webp');
require('../images/hero.jpg');
require('../images/hero_valentin.jpg');

require('../images/hero_valentin.jpg');
require('../images/choose_img.png');

require('../images/logo_icon.svg');
require('../images/discount.svg');
require('../images/email.svg');

require('../images/maintenance.svg');

require('../images/st1.jpg');
require('../images/st2.jpg');
require('../images/st3.jpeg');
require('../images/st4.jpg');
require('../images/st5.jpg');
require('../images/st6.jpg');
require('../images/st7.jpg');
require('../images/divider-line.png');
require('../images/szaloncuki.png');


//mail kepek
require('../images/mail/checked.png');
require('../images/mail/facebook.png');
require('../images/mail/headline.png');
require('../images/mail/instagram.png');
require('../images/mail/stuhmer-logo.png');
require('../images/mail/youtube.png');

//ikonok
require('../icons/order_empty_icon.svg');
require('../icons/delivery_icon.svg');
require('../icons/offer_empty.svg');
require('../icons/favorite_empty_icon.svg');
require('../icons/search_empty_icon.svg');
require('../icons/address_empty_icon.svg');
require('../icons/career_empty_icon.svg');
require('../icons/cart_empty_icon.svg');
require('../icons/play.svg');
require('../icons/info-icon.svg');
require('../icons/alert.svg');
require('../icons/years_icon.svg');
require('../icons/arrow-left.svg');
require('../icons/arrow-down.svg');
require('../icons/arrow-right.svg');
require('../icons/download.svg');
require('../icons/chev-next.svg');
require('../icons/chev-back.svg');
require('../icons/cart.svg');
require('../icons/cart-disabled.svg');
require('../icons/close.svg');
require('../icons/close-gray.svg');
require('../icons/close-dark.svg');
require('../icons/hamburger.svg');
require('../icons/pwd-hide.svg');
require('../icons/pwd-icon.svg');
require('../icons/search.svg');
require('../icons/tick-green.svg');
require('../icons/tick.svg');
require('../icons/user.svg');
require('../icons/favourite.svg');
require('../icons/favourite-medium-selected.svg');
require('../icons/favourite-medium-standard.svg');
require('../icons/favourite-small-selected.svg');
require('../icons/favourite-small-standard.svg');
require('../icons/close-small.svg');
require('../icons/close-small.svg');
require('../icons/menu-tick.svg');
require('../icons/category.svg');
require('../icons/chevron-back.svg');
require('../icons/chevron-next.svg');
require('../icons/times-red.svg');
require('../icons/360.svg');
require('../icons/marker.png');
require('../icons/route.svg');
require('../icons/plus.svg');
require('../icons/minus.svg');


require('../icons/fb.svg');
require('../icons/yt.svg');
require('../icons/insta.svg');


// Tooltip
window.Popper = require('popper.js').default;
window.tippy = require('tippy.js').default;


